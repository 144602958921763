// GoogleAd.js
import React, { useEffect } from 'react';

const GoogleAd = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Ad error:', e);
    }
  }, []);

  return (
    <>
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-7142631461001751"
          data-ad-slot="8117274079"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>        
    </>
  );
};

export default GoogleAd;